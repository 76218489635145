import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import DreamGirl from './DreamGirl'; // Import the new component

console.log('DreamGirl:', DreamGirl); // Debugging

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/dreamgirl" element={<DreamGirl />} />
      </Routes>
    </Router>
  </React.StrictMode>
);

reportWebVitals();
