import React, { useState } from 'react';
import './DreamGirl.css';  // Import the external CSS

const DreamGirl = () => {
  const [showForm, setShowForm] = useState(false);
  const [userId, setUserId] = useState('');  // State to hold the user ID

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    // Create the request body
    const requestBody = {
      user_id: userId
    };

    try {
      // Send POST request to the API
      const response = await fetch('http://44.214.82.59/delete-account', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        // Handle success (optional)
        alert('Account deletion request has been submitted successfully.');
      } else {
        // Handle failure (optional)
        alert('Failed to submit account deletion request.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while submitting the request.');
    }
  };

  return (
    <div className="dreamgirl-container">
      <h1 className="dreamgirl-heading">Download DreamGirl App</h1>
      <button
        className="dreamgirl-button"
        onClick={() => (window.location.href = 'https://play.google.com/store')}
      >
        Download App
      </button>
      <button
        className={`dreamgirl-button dreamgirl-button-secondary`}
        onClick={() => setShowForm(true)}
      >
        Request Account/Data Deletion
      </button>
      {showForm && (
        <form onSubmit={handleFormSubmit} className="dreamgirl-form">
          <label htmlFor="userId" className="dreamgirl-label">
            User ID / Phone Number:
          </label>
          <input
            type="text"
            id="userId"
            name="userId"
            required
            placeholder="Enter your User ID or Phone Number"
            className="dreamgirl-input"
            value={userId}
            onChange={(e) => setUserId(e.target.value)} // Update the userId state
          />
          <button type="submit" className="dreamgirl-submit-button">
            Submit
          </button>
        </form>
      )}
    </div>
  );
};

export default DreamGirl;
